export const DBConfig = {
    name: "tallymondb",
    version: 1,
    objectStoresMeta: [
      {
        store: "userinfo",
        storeConfig: { keyPath: "name", autoIncrement: false },
        storeSchema: [],
      }
    ],
  };