exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-generate-room-tsx": () => import("./../../../src/pages/generateRoom.tsx" /* webpackChunkName: "component---src-pages-generate-room-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-using-ssr-tsx": () => import("./../../../src/pages/using-ssr.tsx" /* webpackChunkName: "component---src-pages-using-ssr-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-votingroom-tsx": () => import("./../../../src/pages/votingroom.tsx" /* webpackChunkName: "component---src-pages-votingroom-tsx" */),
  "component---src-pages-youre-kicked-tsx": () => import("./../../../src/pages/youre-kicked.tsx" /* webpackChunkName: "component---src-pages-youre-kicked-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

