/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react';
import { RecoilRoot } from 'recoil';
import { DBConfig } from "./src/components/indexDBconfig/indexDBconfig";
import { initDB } from "react-idb-ssr";

initDB(DBConfig);

export const wrapPageElement = ({element, props}) => {
    return (
        <RecoilRoot {...props}>
            {element}
        </RecoilRoot>
    )
}
